// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-akkordeon-seite-tsx": () => import("./../../../src/templates/AkkordeonSeite.tsx" /* webpackChunkName: "component---src-templates-akkordeon-seite-tsx" */),
  "component---src-templates-downloads-tsx": () => import("./../../../src/templates/Downloads.tsx" /* webpackChunkName: "component---src-templates-downloads-tsx" */),
  "component---src-templates-galerie-tsx": () => import("./../../../src/templates/Galerie.tsx" /* webpackChunkName: "component---src-templates-galerie-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/Home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-standard-seite-tsx": () => import("./../../../src/templates/StandardSeite.tsx" /* webpackChunkName: "component---src-templates-standard-seite-tsx" */),
  "component---src-templates-text-seite-tsx": () => import("./../../../src/templates/TextSeite.tsx" /* webpackChunkName: "component---src-templates-text-seite-tsx" */),
  "component---src-templates-ueber-uns-tsx": () => import("./../../../src/templates/UeberUns.tsx" /* webpackChunkName: "component---src-templates-ueber-uns-tsx" */)
}

